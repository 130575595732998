import React, { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/browser';
import { Provider } from "react-redux";
import { cloneDeep } from 'lodash'

import { Route, createBrowserRouter, RouterProvider, createRoutesFromElements, Outlet } from "react-router-dom";

import store from '../store';
import { set_user_data, delete_old_toasts, add_toast_action } from '../actions';
import {
  get_whoami, get_evento,
  empty_user, connectMessaggiImportantiWs
} from '../api';
import { plain_object, } from '../api/types';
import { sentry_config, DEBUG, TESTING } from '../globals';
import { sentry_log, eventi_attivi } from '../utils'
import { get_csrf_token, configure_drr, empty_ws_state_functional, cleanupWs } from 'django-rest-react'
import { service_worker_handler } from '../handlers'

import {
  Punteggi, Home, ConsegnaRisposta,
  Eventi, NoMatch, Profile, Regolamento,
  SessioneTueSquadre, Sponsor, DeleteCache, ChiSiamo, CookiePolicy,
  LoginError, NuovoResponsabile, DiscordToken, ModificaEvento,
  IscrizioneSquadra
} from '../screens'
import {
  Navbar, Footer,
} from '../components';
import { Loadable, LoadingBar, ToastFeed, APIErrorCatcher } from '../common-components'
import { reducers_map } from '../reducers'
import { createRoot } from 'react-dom/client';


const LayoutFactory = (elem: React.ReactNode, effects: boolean) => () => {

  if (effects) {
    const websockets = useRef([])

    useEffect(() => {
      get_evento({}).then(eventi => {
        const evs = eventi_attivi(eventi);
        websockets.current = evs.map(_ => cloneDeep(empty_ws_state_functional))
        evs.map((evento, idx) => {
          connectMessaggiImportantiWs(websockets.current[idx], evento.id)
        })
      })
      return () => {
        websockets.current.map(cleanupWs)
      }
    }, [])
  }

  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{ minHeight: '100%', height: '100%' }}>
      <div>
        <LoadingBar />
        <Navbar />
        <div style={{ position: 'relative', zIndex: 3 }}>
          <div style={{ position: 'relative', zIndex: 1000 }}>
            <ToastFeed />
          </div>
          <div style={{ position: 'relative', zIndex: 1 }}>
            {elem}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const Layout = LayoutFactory(<Outlet />, true)
const ErrorElement = LayoutFactory(<APIErrorCatcher get_username={s => s.user.username} />, false)


export const ROUTES = (
  <Route path="/*" element={<Layout />} errorElement={<ErrorElement />}>
    <Route index path="" element={<Home />} />
    <Route path="eventi/" element={<Eventi />} />
    <Route path="eventi/non-ufficiali/" element={<Eventi />} />
    <Route path="evento/:pk/" element={<Eventi />} />
    <Route path="eventi/anno/:anno/" element={<Eventi />} />
    <Route path="sessione/:pk/punteggi/" element={<Punteggi />} />
    <Route path="sessione/:pk/punteggi/standard-model/" element={<Punteggi />} />
    <Route path="sessione/:pk/punteggi/scuola/:scuola_id/" element={<Punteggi />} />
    <Route path="sessione/:pk/punteggi/telecronaca/" element={<Punteggi />} />
    <Route path="sessione/:pk/consegna-risposte/" element={<ConsegnaRisposta />} />
    <Route path="evento/:pk/consegna-risposte/" element={<ConsegnaRisposta />} />
    <Route path="sessione/:pk/le-tue-squadre/" element={<SessioneTueSquadre />} />
    <Route path="nuovo-responsabile/" element={<NuovoResponsabile />} />
    <Route path="modifica-responsabile/:pk/" element={<NuovoResponsabile />} />
    <Route path="nuovo-evento/" element={<ModificaEvento />} />
    <Route path="modifica-evento/:pk/" element={<ModificaEvento />} />
    <Route path="nuova-squadra/" element={<IscrizioneSquadra />} />
    <Route path="delete-cache/" element={<DeleteCache />} />
    <Route path="regolamento/" element={<Regolamento />} />
    <Route path="loginerror/" element={<LoginError />} />
    <Route path="accounts/profile/" element={<Profile />} />
    <Route path="accounts/profile/discord/" element={<DiscordToken />} />
    <Route path="sponsor/" element={<Sponsor />} />
    <Route path="about-us/" element={<ChiSiamo />} />
    <Route path="cookie-policy/" element={<CookiePolicy />} />
    <Route path="*" element={<NoMatch />} />
  </Route>
)


export const AppFactory = (creator: typeof createBrowserRouter, store_: typeof store) => () => {
  const router = creator(createRoutesFromElements(ROUTES))
  return (
    <Loadable load={carica}>
      <Provider store={store_}>
        <RouterProvider router={router} />
      </Provider>
    </Loadable>
  )
}
const App = AppFactory(createBrowserRouter, store);

export default App;

const wrapper = document.getElementById("app");
const cusu = document.getElementById("user");
const username = cusu ? cusu.textContent : "__not_logged_in";
const carica: () => Promise<plain_object> =
  async () => {
    const get_connection_toast = (val: string) => {
      return {
        title: "Connessione persa",
        message: "Tentativo di riconnessione in corso",
        generating_object: val,

      }
    }
    configure_drr(
      reducers_map, store, DEBUG,
      val => delete_old_toasts(get_connection_toast(val)),
      val => add_toast_action(get_connection_toast(val)),
    );

    if (navigator.serviceWorker) {
      navigator.serviceWorker.register(
        '/static/frontend/build/service_worker.js', { scope: '/' }).then(
          worker => {
            navigator.serviceWorker, addEventListener('message', service_worker_handler);
            if (navigator.serviceWorker.controller) {
              navigator.serviceWorker.controller.postMessage(JSON.stringify({
                "header": "sessionid",
                "body": {
                  "sessionid": get_csrf_token(),
                }
              }))
            }
          })
    }

    if (username != "__not_logged_in") {
      return get_whoami().then((io) => {
        store.dispatch(set_user_data(io));
        return {};
      }).catch(error => {
        sentry_log(error)
        store.dispatch(set_user_data(empty_user));
        return {};
      });
    } else {
      return new Promise((resolve) => {
        resolve({});
      });
    }
  }


const mapRoutesToArray = (routes: typeof ROUTES) => {
  const paths = [] as string[];
  const children = routes.props.children;
  children.map((route: typeof ROUTES.props.children[0]) => {
    const inner = route.props.path;
    if (!inner) return
    if (Array.isArray(inner)) {
      paths.concat(inner)
    } else {
      paths.push(inner as string)
    }
  })
  return paths;
}


export const generate_sitemap = () => mapRoutesToArray(ROUTES)

if (sentry_config.use_sentry) {
  Sentry.init({
    dsn: sentry_config.dsn,
    release: sentry_config.release,
  });
}

if (!TESTING) {
  createRoot(wrapper).render(<App />)
}
