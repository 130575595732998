import React from 'react';
import { Link } from "react-router-dom";

import { displayWhoami } from '../utils';
import { testing_or_dummy } from '../globals'
import { useEvento, useLogin } from '../reducers';



const Navbar = (props: {}) => {
  const login = useLogin();
  const eventi = useEvento(props);

  let login_block;
  const gascal_logo = testing_or_dummy("gascal-static-logo-link");

  if (login.logged_in) {
    let superuser;
    if (login.user.is_staff) {
      superuser = <>
        <a className="dropdown-item" href="/admin/">Django admin</a>
        <Link className="dropdown-item" to="/delete-cache/">Reset cache</Link>
      </>

    }
    login_block = (
      <>
        <Link className="dropdown-item" to={`/accounts/profile/`}>Il tuo profilo</Link>
        <a className="dropdown-item" href="/accounts/password_change/">Cambia password</a>
        {superuser}
        <a className="dropdown-item" href="/accounts/logout/">Logout</a>
      </>
    );
  } else {
    login_block = (
      <>
        <a className="dropdown-item" href="/accounts/login/">Login</a>
      </>
    );
  }
  const log_name = login.logged_in ? displayWhoami(login.user) : "Login";
  const adesso = new Date()
  const prossimo = eventi.find(ev => {
    // Eventi il cui inizio è più vicino di 2 giorni
    const diff = Math.abs(Math.round(((new Date(ev.inizio)).getTime() - adesso.getTime()) / 1000))
    return diff < 60 * 60 * 24 * 2;
  })

  return (
    <nav className="navbar navbar-nav navbar-dark bg-primary navbar-expand-lg px-3">
      <div className="container">
        <Link to={`/`} className="navbar-brand py-0">
          <img src={gascal_logo} style={{ maxWidth: 40 }} />
        </Link>
        <button
          className="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false" aria-label="Toggle navigation">
          <span><i className="fa fa-bars"></i> Menu</span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav mr-auto">
            {prossimo &&
              <li className="nav-item">
                <Link to={`/evento/${prossimo.id}/`} className="nav-link text-warning">Evento corrente</Link>
              </li>
            }

            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Eventi
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link to={`/eventi/`} className="dropdown-item">Tutti gli eventi OliFis</Link>
                <Link to={`/eventi/anno/2024/`} className="dropdown-item">2023-2024</Link>
                <Link to={`/eventi/anno/2023/`} className="dropdown-item">2022-2023</Link>
                <div className="dropdown-divider"></div>
                <Link to={`/eventi/non-ufficiali/`} className="dropdown-item">Eventi non ufficiali</Link>
              </div>
            </li>
            <li className="nav-item">
              <a href="/albo-oro/" className="nav-link">Albo d'oro</a>
            </li>
            <li className="nav-item">
              <Link to={`/regolamento/`} className="nav-link">Regolamento</Link>
            </li>
            <li className="nav-item">
              <Link to="/sponsor/" className="nav-link">Sponsor</Link>
            </li>
            <li className="nav-item">
              <Link to="/about-us/" className="nav-link">Chi siamo</Link>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item dropdown ml-auto">
              <a className="nav-link dropdown-toggle" href="#"
                id="navbarDropdownMenuLink" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                {log_name}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                {login_block}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
