import { WsStateFunctional, connectWsFunctional } from 'django-rest-react';

import { sentry_log } from '../utils';
import { set_singolo_messaggio_importante, add_toast_action } from '../actions'
import store from '../store'
import { SquadraTabella, ProblemaTabella, PunteggiStateInternal, ClassificaEntry as SquadraJson } from './types'
import {
  set_punteggio_action, add_punteggi_evento_action
} from '../actions';
import { stateToScore } from '../reducers/punteggi_markov'


export const dispatchable_score = (stato: PunteggiStateInternal) => {
  const adesso = new Date()
  const recenti = stato.eventi.filter(ev => (adesso.getTime() - ev.date.getTime() < 3 * 60 * 1000))
  return parse_classifica({
    ora: stato.stato.timestamp.toISOString(),
    json: stateToScore(stato, recenti)
  })
}


export const connectMessaggiImportantiWs = (obj: WsStateFunctional, pk: number) => {
  const msg_handler = (msg: any) => {
    const messaggio = msg.message;
    if (messaggio.type == "new_message") {
      store.dispatch(set_singolo_messaggio_importante([
        { name: "evento", value: `${pk}` }], messaggio.message))
      store.dispatch(add_toast_action({
        title: "Evento in corso",
        message: messaggio.message.testo,
        time: new Date(messaggio.message.data)
      }))
      return;
    }
    sentry_log(new Error(`Messaggio dal ws messaggi importanti ${pk} non decodificato: ${JSON.stringify(messaggio)}`))
  }

  return connectWsFunctional(obj, `/ws/gas/messaggi-importanti/${pk}/`, msg_handler)
}


export interface JsonClassifica {
  json: {
    giuste: number[],
    punteggi_problemi: number[],
    classifica: SquadraJson[],
  }
  ora: string
}


export const connectPunteggiWs = (obj: WsStateFunctional, session_pk: number) => {
  const msg_handler = (msg: any) => {
    if (msg.type == "new_json") {
      const timestamp = {
        ora: msg.json.ora,
        json: msg.json.json,
      } as JsonClassifica;
      const elaborato = parse_classifica(timestamp);
      store.dispatch(set_punteggio_action(elaborato, session_pk));
    }
  }
  return connectWsFunctional(obj, `/ws/gas/sessione/${session_pk}/punteggi_last/`, msg_handler);
}


export const connectPunteggiEventiWs = (obj: WsStateFunctional, session_pk: number, eventonly: boolean = false) => {
  const msg_handler = (msg: any) => {
    if (msg.type == "npe") {
      const { json } = msg;
      const recvd = new Set(Object.keys(json));
      const expected = ["p", "s", "k", "d", "id"]
      const difference = expected.filter(x => !recvd.has(x))
      if (difference.length > 0) {
        sentry_log(new Error(`Received malformed event! ${json}`))
        return;
      }
      if (json.k == "r" && !json.hasOwnProperty("c")) {
        sentry_log(new Error(`Received malformed event (no correctness of answer)! ${json}`))
        return;
      }
      store.dispatch(add_punteggi_evento_action(session_pk, {
        problema: json.p, squadra: json.s,
        corretto: json.c, kind: json.k,
        date: new Date(Date.parse(json.d)),
        id: json.id,
      }))
      if (!eventonly) {
        const last = store.getState().punteggi_markov[session_pk.toString()]
        store.dispatch(set_punteggio_action(dispatchable_score(last), session_pk))
      }
    } else if (msg.type == "refresh_page") {
      window.location.reload();
    } else {
      console.warn("Received unknown message", msg)
    }
  }

  return connectWsFunctional(obj, `/ws/gas/sessione/${session_pk}/punteggi_eventi/`, msg_handler);
}


export const parse_classifica = (json: JsonClassifica) => {
  const classifica = json.json.classifica;
  let ret: SquadraTabella[] = [];
  for (let j = 0; j < classifica.length; j++) {
    let squadra = classifica[j];
    let prbs: ProblemaTabella[] = [];
    for (var i = 0; i < squadra.problemi.length; i++) {
      let pr: ProblemaTabella;
      const p = squadra.problemi[i];
      const pti_prob = p.reduce((acc, curr) => acc + curr);
      const risolto = p[0] > 0 || p[1] > 0;
      try {
        pr = {
          punti: pti_prob,
          punti_parziali: p,
          jolly: (squadra.jolly === i),
          nuovo: squadra.nuovi.includes(i),
          risolto: risolto
        };
      } catch (err) {

      }
      prbs.push(pr);
    }
    let sq_details: SquadraTabella = {
      posizione: j + 1,
      nome: squadra.nome,
      ospite: squadra.ospite,
      problemi: prbs,
      totale: squadra.punteggio_totale,
      id: squadra.id
    };
    ret.push(sq_details);
  }
  const newclass = sortClassifica(ret, 1, "n", 0);
  const last_upd = new Date(Date.parse(json.ora))
  return {
    ora: last_upd,
    classifica: newclass,
    punteggi_problemi: json.json.punteggi_problemi,
  };
}



export function sortClassifica(
  squadre: SquadraTabella[],
  reverse: -1 | 1, field: "p" | "n" | "prob",
  idx: number
): SquadraTabella[] {

  let func = (a: SquadraTabella, b: SquadraTabella): number => { return 0; };
  switch (field) {
    case "p":
      func = (a, b) => {
        return reverse * (a.posizione - b.posizione);
      }
      break;
    case "n":
      func = (a, b) => {
        if (a.nome === b.nome) {
          return 0;
        }
        let appo = a.nome > b.nome ? 1 : -1;
        return reverse * appo;
      }
      break;
    case "prob":
      func = (a, b) => {
        return reverse * (a.problemi[idx].punti - b.problemi[idx].punti)
      }
      break;
  }
  return squadre.sort(func);
}
