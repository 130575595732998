import React, { Component, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { AppState, useEvento } from '../reducers';
import { chunk, rgb_to_rgba } from '../utils'
import { sponsors, themeColors, simil_header_style, locandina_casio } from '../globals'

const get_division_from_window_size = () => {
  const horiz = (window.innerWidth > window.innerHeight);
  return {
    divisione_contributors: horiz ? 4 : 2,
  }
}

const check_horizontal = (setHoriz: (_: boolean) => void) => {
  const horiz = (window.innerWidth > window.innerHeight);
  setHoriz(horiz)
}

const Home = (props: {}) => {
  // Lo lasciamo qua volontariamente così preloadi gli eventi.
  const eventi = useEvento(props);
  const [horiz, setHoriz] = useState(window.innerWidth > window.innerHeight);
  const { divisione_contributors } = get_division_from_window_size();
  const horiz_size = horiz ? '400px' : '200px';
  const location = useLocation();
  const navigate = useNavigate();
  if (location.hash == "#/about-us/") {
    navigate("/about-us/")
  }

  useEffect(() => {
    document.title = "Gara a Squadre di fisica";
    window.addEventListener('resize', () => check_horizontal(setHoriz));
  }, [])

  return (
    <div className="main-container mb-5">
      <div style={{ width: '100%' }}>
        <div id="homepage-image-first">
          <div style={{ zIndex: 10 }} className="container">
            <img src="/static/gascal/images/gas.png" style={{ maxWidth: horiz_size }} />
            <p className="px-2 text-center">
              Benvenuto sulla pagina della Gara a Squadre di Fisica, parte del <a href="https://olifis.it/">Progetto OLIFIS</a> dell'<a href="https://aif.it/">AIF</a> per studenti delle scuole secondarie superiori.
            </p>
          </div>
        </div>

        <div style={{ backgroundColor: 'rgba(255, 255, 255, 1.0)' }}>
          <div className="container py-5">
            <div style={simil_header_style}><Link to="/sponsor/">Sponsor</Link></div>
            {chunk(sponsors, divisione_contributors).map((chunk, idx1) =>
              <div className="flex-row" key={idx1}>
                {chunk.map((sponsor, idx2) =>
                  <div className="bannerino flex-grow-1" key={idx2} style={{ flexBasis: '100%' }}>
                    <a href={sponsor.website}>
                      <img
                        src={sponsor.logo_url}
                        className="img-thumbnail"
                        alt={sponsor.name}
                        style={{ borderStyle: 'none', maxHeight: '200px' }}
                      />
                    </a>
                  </div>
                )}
              </div>
            )}


          </div>
        </div>

        <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
          {/* @ts-ignore */}
          <div className="container py-4" align="center">
            <div style={{ ...simil_header_style }}>Finale Nazionale 2024</div>
            <p style={{ textAlign: 'center' }}>
              Il 12 aprile si è svolta la seconda edizione della Finale Nazionale. Puoi vedere <a href="https://www.youtube.com/watch?v=we7lpwqflzQ">la registrazione</a> della gara e guardare la <Link to="/sessione/5/punteggi/">classifica</Link>. C'è inoltre una <a href="/finale-nazionale/2024/">pagina dedicata</a> all'evento.
            </p>
            <iframe
              width="560" height="315" title="YouTube video player"
              frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
              src="https://www.youtube.com/embed/we7lpwqflzQ" style={{ display: "block", maxWidth: "100%" }}
            ></iframe>

          </div>
        </div>

        <div style={{ backgroundColor: 'rgba(255, 255, 255, 1.0)' }} id="homepage-chi-siamo">
          <div className="container py-4">
            <div style={{ ...simil_header_style }}>Chi siamo</div>
            <p style={{ textAlign: 'center' }}>
              La competizione è organizzata da studenti universitari volontari, principalmente della <a href="https://www.sns.it/">Scuola Normale Superiore</a>. Puoi trovare informazioni aggiuntive sulla pagina <Link to={"/about-us/"}>Chi siamo</Link>.
            </p>
          </div>
        </div>

        <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }} >
          <br />
          <div className="container py-4">
            <div style={simil_header_style}>Regolamento</div>
            <div className="flex-row">
              <div className="text-row">
                Possono partecipare squadre di studenti delle scuole secondarie superiori, fino a 5 componenti. I componenti di ogni squadra collaborano per risolvere problemi di Fisica a risposta numerica. La classifica viene aggiornata in tempo reale ed è visibile a tutti. Puoi consultare il <Link to="/regolamento/">regolamento esteso</Link> o andare a visitare la pagina dei <Link to="/eventi/">prossimi eventi</Link>.
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>

        <div style={{ backgroundColor: 'rgba(255, 255, 255, 1.0)' }}>
          <div className="container py-4">
            <div style={simil_header_style}>Eventi di formazione</div>
            <p>
              CASIO ha supportato le squadre iscritte alla prima
              edizione del campionato con l’utilizzo della calcolatrice
              grafica senza CAS con tre momenti di formazione gratuita
              online, diretta ai docenti e agli studenti sulle tematiche più
              vicine a quelle che normalmente vengono affrontate durante
              le gare approcciate con la calcolatrice grafica.

              <br />
              Durante il 2024 saranno presenti altri eventi di formazione, sempre sotto forma di webinar, in cui si mostrerà anche la soluzione
              ad alcuni dei problemi proposti nel 2023. Al momento sono state decise le date di questi eventi, consultate nuovamente la pagina per sapere gli argomenti e avere i link a cui seguire i vari eventi.
            </p>

            <table className="table table-responsive table-striped table-hover">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Data e ora</th>
                  <th scope="col">Argomento</th>
                  <th scope="col">Link</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">13/11/2023 16:00-17:00</td>
                  <td>
                    Presentazione di GaS
                    <br />
                    Intervengono: Fabio Zoratti responsabile nazionale del progetto, Prof.ssa Cristina Arienti, Guglielmo Di Grazia capitano della squadra vincitrice dell'edizione 2023
                  </td>
                  <td>
                    <a href="https://www.casio-edu.it/webinar/aif-e-casio-presentano-la-gara-a-squadre-di-fisica-2024/295/">
                      <button className="btn btn-info">Link</button>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td scope="row">22/1/2024 16:00-17:00</td>
                  <td>
                    Incontro generale sull'uso della calcolatrice
                    <br />
                    Relatore: Prof.ssa Cristina Arienti</td>
                  <td>
                    <a href="https://www.youtube.com/watch?v=1L25d5uQRYk">
                      <button className="btn btn-info">Studenti</button>
                    </a>
                    <a href="https://www.casio-edu.it/webinar/formazione-campionati-di-fisica-incontro-generale-sulluso-della-calcolatrice-/308/">
                      <button className="btn btn-info">Docenti</button>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td scope="row">7/2/2024 16:00-17:00</td>
                  <td>
                    Equazioni e soluzioni di qualche problema di fisica
                    <br />
                    Relatore: Prof.ssa Cristina Arienti
                  </td>
                  <td>
                    <a href="https://youtube.com/live/Rxeb5JbuKkM">
                      <button className="btn btn-info">Studenti</button>
                    </a>
                    <a href="https://www.casio-edu.it/webinar/formazione-campionati-di-fisica-equazioni-e-soluzioni-di-qualche-problema-di-fisica/309/">
                      <button className="btn btn-info">Docenti</button>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td scope="row">23/2/2024 16:00-17:00</td>
                  <td>
                    Fit di dati e curve di regressione
                    <br />
                    Relatore: Prof.ssa Cristina Arienti
                  </td>
                  <td>
                    <a href="https://youtube.com/live/WNgMUBq2e8U">
                      <button className="btn btn-info">Studenti</button>
                    </a>
                    <a href="https://www.casio-edu.it/webinar/formazione-campionati-di-fisica-fit-di-dati-e-curve-di-regressione/310/">
                      <button className="btn btn-info">Docenti</button>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              Tutti gli incontri saranno tenuti utilizzando la calcolatrice grafica FX-CG50.
              Chi desidera può scaricare il software emulatore gratuito:<a className="ml-2 btn btn-info" href="https://edu.casio.com/softwarelicense/index.php">Studenti</a><a className="btn btn-info" href="https://www.casio-edu.it/software/emulatori/licenze-gratuite-docenti/">Docenti</a>
            </p>
            <div className="row justify-content-center">
              <iframe style={{ width: '600px', height: '600px' }} src={locandina_casio}></iframe>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
          <br />
          <div className="container py-4">
            <div style={simil_header_style}>Calendario finale nazionale 2024</div>
            <table className="table table-responsive table-striped table-hover">
              <thead className="thead-dark">
                <tr>
                  <th></th>
                  <th scope="col">Mercoledì 10/4/2024</th>
                  <th scope="col">Giovedì 11/4/2024</th>
                  <th scope="col">Venerdì 12/4/2024</th>
                  <th scope="col">Sabato 13/4/2024</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Mattina</th>
                  <td></td>
                  <td>Gara sperimentale individuale</td>
                  <td>Gara teorica individuale</td>
                  <td><b>Cerimonia premiazione condivisa</b></td>
                </tr>
                <tr>
                  <th scope="row">Pomeriggio</th>
                  <td>Arrivo partecipanti gara individuale</td>
                  <td><b>Arrivo partecipanti gara a squadre</b></td>
                  <td><b>Gara a squadre</b></td>
                  <td><b>Partenza partecipanti</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div style={{ backgroundColor: rgb_to_rgba(themeColors["wet-asphalt"], 0.9) }}>
          {/* @ts-ignore */}
          <div className="container py-4" align="center">
            <div style={{ color: 'rgba(255, 255, 255, 0.8)', ...simil_header_style }}>Finale Nazionale 2023</div>
            <p style={{ color: 'rgba(255, 255, 255, 0.9)', textAlign: 'center' }} >
              Il 14 aprile si è tenuta la Finale Nazionale 2023. Informazioni dettagliate sulla manifestazione si possono trovare su <a href="/finale-nazionale/2023/">una pagina dedicata</a>, mentre per la classifica si può consultare la <Link to={"/eventi/"}>pagina degli eventi</Link> e seguire l'evento in diretta da qui.
            </p>

            <iframe
              width="560" height="315" title="YouTube video player"
              frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
              src="https://www.youtube.com/embed/7sna3nf1i-A" style={{ display: "block", maxWidth: "100%" }}
            ></iframe>
          </div>
        </div>

      </div>
    </div >
  );
};

export default Home;
