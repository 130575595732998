import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CasellaPunteggio from '../components/CasellaPunteggio';
import { GithubPicker, ColorResult } from 'react-color';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import { SquadraTabella as Squadra, SquadraInSessione } from '../api/types';
import './RigaPunteggi.scss'

interface IProps {
  squadra: Squadra,
  nome: string,
  posizione: number,
  evidenzia: boolean,
  dettagli_squadra: SquadraInSessione,
  mostra_scuola: boolean,
}

interface ColoriParams {
  [squadra_name: string]: ColorResult,
}


const getColoreStart: (squadra: Squadra, colori: ColoriParams) => ColorResult = (squadra, colori) => {
  const colore_squadra = (colori != null) ? colori[squadra.nome] : null;
  return (colore_squadra == undefined) ? null : colore_squadra
}

const getColori: (l: ReturnType<typeof useLocation>) => ColoriParams = (location) => {
  return JSON.parse(decodeURIComponent((new URLSearchParams(location.search)).get("colori")))
}

const encodeColori = (topush: ColoriParams): string => {
  return new URLSearchParams({ colori: JSON.stringify(topush) }).toString()
}

const newUrl: (location: ReturnType<typeof useLocation>, colori: string) => string = (location, colori) => {
  const base = location.pathname.split('?')[0];
  return base + "?" + colori + location.hash;
}


const RigaPunteggi = (props: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const colori = getColori(location);
  const [color, setColor] = useState(getColoreStart(props.squadra, colori))


  const resetColor = () => {
    const nuovicolori = getColori(location);
    let newpath = "";
    if (nuovicolori != null) {
      delete nuovicolori[props.squadra.nome];
      newpath = (Object.keys(colori).length === 0) ? "" : encodeColori(nuovicolori);
    }
    navigate(newUrl(location, newpath), { replace: true })
    setColor(null);
  }

  const handleChangeComplete = (color: ColorResult) => {
    setColor(color)

    let old = getColori(location);
    let topush = { [props.squadra.nome]: color };
    let colori = encodeColori(Object.assign({}, old, topush));
    navigate(newUrl(location, colori))
  }

  let colorpicker = (
    <Popover title="Evidenzia con un colore" id="color-picker">
      <GithubPicker
        onChangeComplete={handleChangeComplete}
        triangle="hide"
      />
      <div className="row justify-content-center">
        <button className="btn btn-warning" onClick={resetColor}>Reset</button>
      </div>
    </Popover >
  );

  const penalita = !!props.dettagli_squadra?.penalita ? <span className="text-danger">(Pen {props.dettagli_squadra.penalita})</span> : null;
  let extra_props = color ? { "bgcolor": color.hex } : null;
  return (
    <>
      <OverlayTrigger trigger="click" placement="top" overlay={colorpicker} rootClose>
        <tr data-toggle="tooltip"
          data-placement="top" title="Premi per scegliere un colore"
          {...extra_props}
        >
          <td>{props.posizione}</td>
          <td>
            {props.nome} {penalita} {props.evidenzia && <i className="fa fa-circle text-success" />}
            {props.mostra_scuola && <><br className="rigapunteggi" /> <small>{props?.dettagli_squadra.scuola.nome}, {props?.dettagli_squadra.scuola.indirizzo?.citta.name}</small></>}
          </td>
          <td>{props.squadra.totale}</td>
          {props.squadra.problemi.map(
            (pti, idx) => <CasellaPunteggio key={idx} dettagli={pti} />)
          }
        </tr>
      </OverlayTrigger>
    </>
  );
}

export default RigaPunteggi;
