import React, { useEffect } from 'react';

import { Tile } from '../components'
import { testing_or_dummy } from '../globals'


const Regolamento = () => {
  useEffect(() => {
    document.title = "Regolamento - GAS";
  }, [])
  const r2023 = testing_or_dummy("regolamento-2023");
  const ist2023 = testing_or_dummy("istruzioni-2023");
  const r2024 = testing_or_dummy("regolamento-2024");
  const ist2024 = testing_or_dummy("istruzioni-2024");

  return (
    <div className="container">
      <h1 className="page-header">Regolamento</h1>
      <p>Qui sotto alleghiamo le regole della competizione, anno per anno, in formato PDF.</p>
      <h1>2024</h1>
      <div className="d-flex flex-wrap justify-content-center">
        <Tile
          url={r2024}
          absolute={true}
          color="info" text="Regolamento 2024"
          icon="fa-balance-scale"
        />
        <Tile
          url={ist2024}
          absolute={true}
          color="wet-asphalt" text="Logistica 2024"
          icon="fa-cogs"
        />
      </div>
      <h1>2023</h1>
      <div className="d-flex flex-wrap justify-content-center">
        <Tile
          url={r2023}
          absolute={true}
          color="info" text="Regolamento 2023"
          icon="fa-balance-scale"
        />
        <Tile
          url={ist2023}
          absolute={true}
          color="wet-asphalt" text="Logistica 2023"
          icon="fa-cogs"
        />
      </div>
    </div>
  );
}

export default Regolamento;
