import { combineReducers } from 'redux';
import { useSelector } from 'react-redux'

import {
  Studente, Sessione, Squadra,
  City, Evento, Country, Regione, Scuola,
  PunteggiSessione, MessaggioImportante
} from '../api/types';
import {
  Cache, StructuredCache,
  loadingReducer, LoadingState,
  tentativoReducer, TentativoState
} from 'django-rest-react';

import { loginReducer, LoginState } from './login';
import { punteggiReducer } from './punteggi';
import { toastReducer, ToastState } from './toast'
import { messaggiImportantiReducer } from './messaggi_importanti'
import { reducers_veri, reducers_map } from './cache';
import { punteggiMarkovReducer, PunteggiState } from './punteggi_markov'

export { stateToScore } from './punteggi_markov'

const rootReducer = combineReducers({
  login: loginReducer,
  messaggi_importanti: messaggiImportantiReducer,
  tentativi: tentativoReducer,
  punteggi: punteggiReducer,
  loading: loadingReducer,
  toasts: toastReducer,
  punteggi_markov: punteggiMarkovReducer,
  ...reducers_veri,
});



// Tutto questo non dovrebbe essere necessario ma typescript ha la mamma puttana
export interface AppState {
  login: LoginState,
  cities: StructuredCache<City>
  countries: Cache<Country>
  persone: Cache<Studente>
  eventi: Cache<Evento>
  sessioni: Cache<Sessione>
  regioni: Cache<Regione>
  scuola: StructuredCache<Scuola>
  tue_squadre: Cache<Squadra>
  messaggi_importanti: StructuredCache<MessaggioImportante>
  tentativi: TentativoState
  toasts: ToastState,
  punteggi: PunteggiSessione
  loading: LoadingState,
  punteggi_markov: PunteggiState,
}
/* export type AppState = ReturnType<typeof rootReducer>; */
export const useLogin = () => useSelector((state: AppState) => state.login);
export const usePunteggi = (session_pk: number) => useSelector((state: AppState) => state.punteggi[session_pk]);
export default rootReducer;
export * from './cache';
export * from './punteggi';
