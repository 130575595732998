import {
  get_single_json, get_paginated_json, post_page,
  refresh_cache,
} from 'django-rest-react';
import {
  Squadra, plain_object, UserWhoami,
  PunteggiStatoBackend, SquadraForm
} from './types';

import { problemi_all } from '../reducers';
export * from './websocket'
import { JsonClassifica } from './websocket'
import { JsonTimestamp, DiscordToken } from './auto-types'
export {
  get_top_contributor_timestamp as get_top_contributors,
  post_jolly, post_risposta, delete_risposta, get_evento, post_user_responsabile as post_new_responsabile,
  patch_user_responsabile as patch_responsabile, get_user_responsabile as get_user_secondari,
  get_sessione_detail as get_sessione_by_pk, get_evento_detail as get_evento_by_pk, get_squadra as get_mie_squadre,
  get_jolly, get_risposta, patch_json_timestamp_delete_cache as delete_session_cache, post_evento_creation as post_evento,
  patch_evento_creation as patch_evento, get_problema, post_evento_role_invite_detail_invita as post_invito_role,
} from './auto-apis'
import { get_squadra_in_sessione_in_sessione } from './auto-apis'


export const get_squadre_sessione = (pk: number) => get_squadra_in_sessione_in_sessione({ sessione: pk })


export async function get_tutti_punteggi(session_pk: number): Promise<JsonClassifica[]> {
  return get_paginated_json<JsonTimestamp>(`/api/gas/sessione/${session_pk}/punteggi/`).then(
    ans => ans.map(item => {
      return {
        json: item.json as unknown as JsonClassifica["json"],
        ora: item.ora
      };
    })
  );
}

export function refresh_problemi(event_pk: number) {
  refresh_cache(problemi_all, [{ name: 'evento', value: event_pk.toString() }]);
}

export const empty_user: UserWhoami = {
  id: -1,
  is_superuser: false,
  is_staff: false,
  username: "",
  last_name: "",
  first_name: "",
  email: "",
  responsabile: {
    id: -1,
    attivo: false,
    user: -1,
    puo_iscriversi_gare_ufficiali: false,
    newsletter: false,
    ruolo: 'sp',
    scuole: [],
    squadre: [],
  },
}


export const request_email_confirmation = async () => post_page<string>("/ext-user/email-confirmation-resend/", {});
export const get_time = () => get_single_json<string>("/api/time/");

export const get_whoami = () => get_single_json<UserWhoami>("/api/ext-user/whoami/")

export const get_markov_stato = (pk: number) => get_single_json<PunteggiStatoBackend>(`/api/gas/sessione/${pk}/stato/`)

export const get_discord_token = (user: string) => post_page<Omit<DiscordToken, "user">>("/api/gas/discord-token/", { user: user })
export const post_squadra = (data: SquadraForm) => post_page<Squadra>("/api/gas/squadra/", data as unknown as plain_object);
